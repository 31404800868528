
// mutate variables
$primary: #0eb4b4;

// fix offcanvas horizontal width
// with bootstrap 5.2
$offcanvas-horizontal-width:  100%;


@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: 0.75rem,
  8: 0.5rem
);

// adding array of custom style
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
  7: 2rem,
  8: 1.75rem,
  9: 1.5rem,
  10: 1.25rem,
  11: 1rem,
  12: 0.75rem,
);


@import "bootstrap";


// override bootstrap
.btn-primary {
  color: #fff;
  &:hover {
    color: #fff;
  }
}
.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary,
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle,
.btn-check:focus + .btn-primary,
.btn-primary:focus{
  color: #fff;
}
